<template>
    <div v-if="!wspLoader" class="worckspace__body">
        <page-header :title="type.name" icon="box" :navlinks="navlinks" />
        <div class="form">
            <div class="form__row">
                <div class="form__label">Название</div>
                <div class="form__input"><input type="text" v-model="type.name"></div>
                <div class="form__input"><button @click="update">Сохранить</button></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'PacksTypesEdit',
    data(){
        return {
            type: null,
            navlinks: [
                {
                    title: 'Назад к перечню типов',
                    route: '/packs/types',
                    icon: 'arrow-left'
                }
            ]
        }
    },
    methods:{
        ...mapActions({
            updateType: 'PackTypeUpdate'
        }),
        update(){
            const payload = {
                id: this.type.id,
                name: this.type.name
            }
            this.updateType(payload);
        }
    },
    computed: {
        ...mapGetters({
            wspLoader: 'GetWspLoader',
            types: 'GetPackTypes',
        })
    },
    created(){
        this.type = this.types.find(el => el.id == this.$route.params.id)
    }
}
</script>